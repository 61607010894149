<template>
  <div class="nd-collapse mt-4 px-5" :class="{ divider: props.divider }">
    <n-collapse v-bind="$attrs" arrow-placement="right">
      <template v-for="item in props.list" :key="item.title">
        <n-collapse-item v-if="item.content || item.custom">
          <template #arrow><div class="placeholder"></div></template>
          <template #header="{ collapsed }">
            <div class="flex items-start justify-between flex-1 text-lg gap-2 pb-3 cursor-pointer" :class="props.headerClass">
              <div class="py-1">{{ item.title }}</div>
              <svg-icon name="arrow-up" class="w-8 h-8 p-1.5 flex-shrink-0 transition-transform transform" :class="{ 'rotate-180': collapsed }" />
            </div>
          </template>
          <slot name="content" :item="item">
            <div class="flex flex-col content-wrapper text-base pb-4">
              <p :class="props.contentClass" v-html="item.content"></p>
            </div>
          </slot>
        </n-collapse-item>
      </template>
    </n-collapse>
  </div>
</template>

<script setup lang="ts" generic="T extends CollapseItem">
import { NCollapse, NCollapseItem } from 'naive-ui'

export interface CollapseItem extends Record<string, unknown> {
  title: string
  content?: string
  custom?: boolean
}

const props = withDefaults(
  defineProps<{
    list: T[]
    divider?: boolean
    headerClass?: string
    contentClass?: string
  }>(),
  {
    divider: false,
  }
)
</script>

<style lang="scss" scoped>
.nd-collapse {
  .content-wrapper {
    :deep(a) {
      @apply text-yellow-50;
    }
  }

  &.divider {
    :deep(.n-collapse) {
      .n-collapse-item:has(+ .n-collapse-item) {
        @apply border-t-0 border-b border-gray-40;
      }
    }
  }
}
</style>
